// ** Mock Adapter
import mock from '../mock'

const data = {
  adverts: [
    {
      id: '1',
      title: 'Carousel 1',
      category: 'carousel',
      status: 'show',
      image: require('@src/assets/images/elements/beats-headphones.png').default
    },
    {
      id: '2',
      title: 'Carousel 2',
      category: 'carousel',
      status: 'not show',
      image: require('@src/assets/images/elements/apple-watch.png').default
    },
    {
      id: '3',
      title: 'Popup 1',
      category: 'popup',
      status: 'not show',
      image: require('@src/assets/images/elements/ipad-pro.png').default
    },
    {
      id: '4',
      title: 'Popup 2',
      category: 'popup',
      status: 'show',
      image: require('@src/assets/images/elements/macbook-pro.png').default
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/adverts/list/all-data').reply(200, data.adverts)

// POST: Add new user
mock.onPost('/apps/adverts/add').reply(config => {
  // Get event from post data
  const advert = JSON.parse(config.data)
  const highestValue = data.adverts.reduce((a, b) => (a.id > b.id ? a : b)).id

  advert.id = highestValue + 1

  data.adverts.push(advert)

  return [201, { advert }]
})

// GET Updated DATA
mock.onGet('/api/adverts/list/data').reply(config => {
  const {
    q = '',
    page = 1,
    perPage = 10,
    sort = 'asc',
    sortColumn = 'name'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.adverts.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    advert =>
      (advert.name.toLowerCase().includes(queryLowered))
  )
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      adverts: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET USER
mock.onGet('/api/adverts/advert').reply(config => {
  const { id } = config
  const advert = data.adverts.find(i => i.id === id)
  return [200, { advert }]
})

// GET CAROUSEL
mock.onGet('/api/adverts/list/carousel').reply(() => {
  const advert = data.adverts.filter(i => i.category === 'carousel')
  return [200, { advert }]
})

// GET POPUP
mock.onGet('/api/adverts/list/popup').reply(() => {
  const advert = data.adverts.filter(i => i.category === 'popup')
  return [200, { advert }]
})

mock.onPost('/apps/adverts/update').reply(config => {
  const advertData = JSON.parse(config.data).data
  const advert = data.adverts.find(advert => advert.id === advertData.id)
  Object.assign(advert, advertData)
  return [200, { advert }]
})

// DELETE: Deletes User
mock.onDelete('/apps/adverts/delete').reply(config => {
  // Get user id from URL
  let advertId = config.id

  // Convert Id to number
  advertId = Number(advertId)

  const advertIndex = data.adverts.findIndex(t => t.id === advertId)
  data.adverts.splice(advertIndex, 1)

  return [200]
})