// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axiosInstance from '../../../../configs/axios'

export const getProfile = createAsyncThunk('appProfile/getProfile', async () => {
  const response = await axiosInstance.get(`/api/profile-admin`)
  return response.data.data
})

export const updateProfile = createAsyncThunk('appProfile/updateProfile', async (profile, { dispatch }) => {
  const response = await axiosInstance.post(`/api/update-profile-admin`, profile, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  await dispatch(getProfile())
  return response.data
})

export const changePassword = createAsyncThunk('appProfile/changePassword', async (profile, { dispatch }) => {
  const response = await axiosInstance.post(`/api/admin-change-password`, profile, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  await dispatch(getProfile())
  return response.data
})

export const appProfileSlice = createSlice({
  name: 'appProfile',
  initialState: {
    data: [],
    loading: false
  },
  // reducers: {
  //   resetSelectedProfile: (state, action) => {
  //     state.selectedProfile = action.payload
  //   }
  // },
  extraReducers: builder => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.loading = true
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
  }
})

export const { resetSelectedProfile } = appProfileSlice.actions

export default appProfileSlice.reducer
