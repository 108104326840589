// ** Mock Adapter
import mock from '../mock'

const data = {
  vouchers: [
    {
      id: '1',
      name: 'Voucher 1',
      description: 'Voucher 1 ini dapat digunakan untuk mengambil barang 1',
      image: require('@src/assets/images/elements/apple-watch.png').default
    },
    {
      id: '2',
      name: 'Voucher 2',
      description: 'Voucher 1 ini dapat digunakan untuk mengambil barang 2',
      image: require('@src/assets/images/elements/beats-headphones.png').default
    },
    {
      id: '3',
      name: 'Voucher 3',
      description: 'Voucher 3 ini dapat digunakan untuk mengambil barang 3',
      image: require('@src/assets/images/elements/iphone-x.png').default
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/vouchers/list/all-data').reply(200, data.vouchers)

// POST: Add new user
mock.onPost('/apps/vouchers/add').reply(config => {
  // Get event from post data
  const voucher = JSON.parse(config.data)
  const highestValue = data.vouchers.reduce((a, b) => (a.id > b.id ? a : b)).id

  voucher.id = highestValue + 1

  data.vouchers.push(voucher)

  return [201, { voucher }]
})

// GET Updated DATA
mock.onGet('/api/vouchers/list/data').reply(config => {
  const {
    q = '',
    page = 1,
    perPage = 10,
    sort = 'asc',
    sortColumn = 'name'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.products.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    voucher =>
      (voucher.name.toLowerCase().includes(queryLowered))
  )
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      vouchers: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET USER
mock.onGet('/api/vouchers/voucher').reply(config => {
  const { id } = config
  const voucher = data.vouchers.find(i => i.id === id)
  return [200, { voucher }]
})

mock.onPost('/apps/vouchers/update').reply(config => {
  const voucherData = JSON.parse(config.data).data
  const voucher = data.vouchers.find(voucher => voucher.id === voucherData.id)
  Object.assign(voucher, voucherData)
  return [200, { voucher }]
})

// DELETE: Deletes User
mock.onDelete('/apps/vouchers/delete').reply(config => {
  // Get user id from URL
  let voucherId = config.id

  // Convert Id to number
  voucherId = Number(voucherId)

  const voucherIndex = data.vouchers.findIndex(t => t.id === voucherId)
  data.vouchers.splice(voucherIndex, 1)

  return [200]
})