// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axiosInstance from '../configs/axios'

export const getTotalProduct = createAsyncThunk('appDashboard/getTotalProduct', async () => {
  const response = await axiosInstance.get('/api/total-produk')
  return response.data
})

export const getTotalTransaction = createAsyncThunk('appDashboard/getTotalTransaction', async () => {
  const response = await axiosInstance.get('/api/total-transaksi')
  return response.data
})

export const getTotalIncome = createAsyncThunk('appDashboard/getTotalIncome', async () => {
  const response = await axiosInstance.get('/api/total-pendapatan')
  return response.data
})

export const getTotalNewMember = createAsyncThunk('appDashboard/getTotalNewMember', async () => {
  const response = await axiosInstance.get('/api/total-new-member')
  return response.data
})

export const getBestSellingProduct = createAsyncThunk('appDashboard/getBestSellingProduct', async () => {
  const response = await axiosInstance.get('/api/product-best-selling')
  return response.data
})

export const getBestIncomeProduct = createAsyncThunk('appDashboard/getBestIncomeProduct', async () => {
  const response = await axiosInstance.get('/api/product-best-income')
  return response.data
})

export const getTopMemberTransaction = createAsyncThunk('appDashboard/getTopMemberTransaction', async () => {
  const response = await axiosInstance.get('/api/member-top-transaction')
  return response.data
})

export const getTopMemberPoint = createAsyncThunk('appDashboard/getTopMemberPoint', async () => {
  const response = await axiosInstance.get('/api/member-top-point')
  return response.data
})

export const getLowestProductStock = createAsyncThunk('appDashboard/getLowestProductStock', async () => {
  const response = await axiosInstance.get('/api/lowest-product-stock')
  return response.data
})

export const appDashboardSlice = createSlice({
  name: 'appDashboard',
  initialState: {
    totalProduct: null,
    totalTransaction: null,
    totalIncome: null,
    totalNewMember: null,
    bestSellingProducts: [],
    bestIncomeProducts: [],
    topMemberTransactions: [],
    topMemberPoints: [],
    lowestProductStocks: []
  },
  reducers: {
    resetDashboard: state => {
      state.totalProduct = null
      state.totalTransaction = null
      state.totalIncome = null
      state.totalNewMember = null
      state.bestSellingProducts = []
      state.bestIncomeProducts = []
      state.topMemberTransactions = []
      state.topMemberPoints = []
      state.lowestProductStocks = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getTotalProduct.fulfilled, (state, action) => {
        state.totalProduct = action.payload.total_produk
      })
      .addCase(getTotalTransaction.fulfilled, (state, action) => {
        state.totalTransaction = action.payload.total_transaksi
      })
      .addCase(getTotalIncome.fulfilled, (state, action) => {
        state.totalIncome = action.payload.total_pendapatan
      })
      .addCase(getTotalNewMember.fulfilled, (state, action) => {
        state.totalNewMember = action.payload.total_new_member
      })
      .addCase(getBestSellingProduct.fulfilled, (state, action) => {
        state.bestSellingProducts = action.payload.data
      })
      .addCase(getBestIncomeProduct.fulfilled, (state, action) => {
        state.bestIncomeProducts = action.payload.data
      })
      .addCase(getTopMemberTransaction.fulfilled, (state, action) => {
        state.topMemberTransactions = action.payload.data
      })
      .addCase(getTopMemberPoint.fulfilled, (state, action) => {
        state.topMemberPoints = action.payload.data
      })
      .addCase(getLowestProductStock.fulfilled, (state, action) => {
        state.lowestProductStocks = action.payload.data
      })
  }
})

export const { resetDashboard } = appDashboardSlice.actions

export default appDashboardSlice.reducer
