// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axiosInstance from '../../../../configs/axios'

export const getData = createAsyncThunk('appCategories/getData', async params => {
  const response = await axiosInstance.get('/api/categories', { params })
  return {
    params,
    data: response.data.data.data,
    totalPages: response.data.data.total
  }
})

export const getCategory = createAsyncThunk('appCategories/getCategory', async id => {
  const response = await axiosInstance.get(`/api/categories/${id}/edit`)
  return response.data.data
})

export const addCategory = createAsyncThunk('appCategories/addCategory', async (category, { dispatch, getState }) => {
  await axiosInstance.post('/api/categories', category, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  await dispatch(getData(getState().categories.params))
  return category
})

export const updateCategory = createAsyncThunk('appCategories/updateCategory', async (category, { dispatch, getState }) => {
  const response = await axiosInstance.post(`/api/categories/${category.id}/update`, category, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  await dispatch(getData(getState().categories.params))
  return response.data
})

export const deleteCategory = createAsyncThunk('appCategories/deleteCategory', async (id, { dispatch, getState }) => {
  await axiosInstance.delete(`/api/categories/${id}/delete`)
  await dispatch(getData(getState().categories.params))
  return id
})

export const getSelectCategory = createAsyncThunk('appCompanies/getSelectCategory', async () => {
  const response = await axiosInstance.get(`/api/select-categories`)
  let options = [{ label: 'Pilih Kategori', value: '' }]
  Object.keys(response.data.data).forEach(key => {
    options = [
      ...options, 
      {
        label: response.data.data[key],
        value: key
      }
    ]
  })
  return options
})

export const appCategoriesSlice = createSlice({
  name: 'appCategories',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selectedCategory: null,
    loading: false,
    categoryOptions: []
  },
  reducers: {
    resetSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getData.pending, (state) => {
        state.loading = true
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.selectedCategory = action.payload
      })
      .addCase(getSelectCategory.fulfilled, (state, action) => {
        state.categoryOptions = action.payload
      })
  }
})

export const { resetSelectedCategory } = appCategoriesSlice.actions

export default appCategoriesSlice.reducer
