// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axiosInstance from '../../../../configs/axios'

export const getData = createAsyncThunk('appCompanies/getData', async params => {
  const response = await axiosInstance.get('/api/stores', { params })
  return {
    params,
    data: response.data.data.data,
    totalPages: response.data.data.total
  }
})

export const getCompany = createAsyncThunk('appCompanies/getCompany', async id => {
  const response = await axiosInstance.get(`/api/stores/${id}/edit`)
  return response.data.data
})

export const addCompany = createAsyncThunk('appCompanies/addCompany', async (company, { dispatch, getState }) => {
  await axiosInstance.post('/api/stores', company, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  await dispatch(getData(getState().companies.params))
  return company
})

export const updateCompany = createAsyncThunk('appCompanies/updateCompany', async (company, { dispatch, getState }) => {
  console.log('ha')
  const response = await axiosInstance.post(`/api/stores/${company.id}/update`, company, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  await dispatch(getData(getState().companies.params))
  return response.data
})

export const deleteCompany = createAsyncThunk('appCompanies/deleteCompany', async (id, { dispatch, getState }) => {
  await axiosInstance.delete(`/api/stores/${id}/delete`)
  await dispatch(getData(getState().companies.params))
  return id
})

export const getSelectCompany = createAsyncThunk('appCompanies/getSelectCompany', async () => {
  const response = await axiosInstance.get(`/api/select-stores`)
  let options = [{ label: 'Pilih Cabang', value: '' }]
  Object.keys(response.data.data).forEach(key => {
    options = [
      ...options, 
      {
        label: response.data.data[key],
        value: key
      }
    ]
  })
  return options
})

export const appCompaniesSlice = createSlice({
  name: 'appCompanies',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selectedCompany: null,
    loading: false,
    companyOptions: [
      { label: 'Pilih Cabang', value: '' },
      { label: 'Store 1', value: '1' },
      { label: 'Store 2', value: '2' }
    ]
  },
  reducers: {
    resetSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getData.pending, (state) => {
        state.loading = true
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.selectedCompany = action.payload
      })
      .addCase(getSelectCompany.fulfilled, (state, action) => {
        state.companyOptions = action.payload
      })
  }
})

export const { resetSelectedCompany } = appCompaniesSlice.actions

export default appCompaniesSlice.reducer
