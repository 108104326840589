// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** axiosInstance Imports
import axiosInstance from '../../../../configs/axios'

export const getData = createAsyncThunk('appPolicies/getData', async params => {
  const response = await axiosInstance.get('/api/policies', { params })
  return {
    params,
    data: response.data.data,
    totalPages: response.data.data.length
  }
})

export const updatePolicy = createAsyncThunk('appPolicies/updatePolicy', async (policy, { dispatch, getState }) => {
  await axiosInstance.post(`/api/policies/${policy.id}/update`, policy)
  await dispatch(getData(getState().policies.params))
  return policy
})

export const appPoliciesSlice = createSlice({
  name: 'appPolicies',
  initialState: {
    data: [],
    total: 1,
    params: {},
    loading: false,
    selectedPolicy: {}
  },
  extraReducers: builder => {
    builder
      .addCase(getData.pending, (state) => {
        state.loading = true
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appPoliciesSlice.reducer
