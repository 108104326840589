// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** axiosInstance Imports
import axiosInstance from '../../../../configs/axios'

export const getData = createAsyncThunk('appTransactions/getData', async params => {
  const response = await axiosInstance.get('/api/transactions', { params })
  return {
    params,
    data: response.data.data.data,
    totalPages: response.data.data.total
  }
})

export const getTransaction = createAsyncThunk('appTransactions/getTransaction', async id => {
  const response = await axiosInstance.get(`/api/transactions/${id}`)
  return response.data
})

export const addTransaction = createAsyncThunk('appTransactions/addTransaction', async (transaction, { dispatch, getState }) => {
  // console.log(JSON.stringify(transaction))
  // return false
  await axiosInstance.post('/api/add-transaction', JSON.parse(JSON.stringify(transaction)))
  await dispatch(getData(getState().transactions.params))
  return product
})

// export const deleteProduct = createAsyncThunk('appTransactions/deleteProduct', async (id, { dispatch, getState }) => {
//   await axiosInstance.delete(`/api/products/${id}/delete`)
//   await dispatch(getData(getState().products.params))
//   return id
// })

export const appTransactionsSlice = createSlice({
  name: 'appTransactions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selectedTransaction: {
      transaction: {},
      itemTransaction: []
    },
    loading: false,
    transaction: {}
  },
  reducers: {
    setSelectedTransaction: (state, action) => {
      state.selectedTransaction = action.payload
    },
    setTransaction: (state, action) => {
      state.transaction = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getData.pending, (state) => {
        state.loading = true
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getTransaction.pending, (state) => {
        state.loading = true
      })
      .addCase(getTransaction.fulfilled, (state, action) => {
        state.loading = false
        state.selectedTransaction = action.payload
      })
  }
})

export const { setSelectedTransaction, setTransaction } = appTransactionsSlice.actions

export default appTransactionsSlice.reducer
