// ** Mock Adapter
import mock from '../mock'

const data = {
  products: [
    {
      id: '1',
      store_id: 'store_1',
      category_id: 'category_1',
      name: 'Product 1',
      stock: '10',
      price: '100000',
      description: 'Ini Deskripsi Product 1',
      image: require('@src/assets/images/elements/apple-watch.png').default
    },
    {
      id: '2',
      store_id: 'store_2',
      category_id: 'category_2',
      name: 'Product 2',
      stock: '20',
      price: '200000',
      description: 'Ini Deskripsi Product 2',
      image: require('@src/assets/images/elements/beats-headphones.png').default
    },
    {
      id: '3',
      store_id: 'store_3',
      category_id: 'category_3',
      name: 'Product 3',
      stock: '30',
      price: '300000',
      description: 'Ini Deskripsi Product 3',
      image: require('@src/assets/images/elements/iphone-x.png').default
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/products/list/all-data').reply(200, data.products)

// POST: Add new user
mock.onPost('/apps/products/add').reply(config => {
  // Get event from post data
  const product = JSON.parse(config.data)
  const highestValue = data.products.reduce((a, b) => (a.id > b.id ? a : b)).id

  product.id = highestValue + 1

  data.products.push(product)

  return [201, { product }]
})

// GET Updated DATA
mock.onGet('/api/products/list/data').reply(config => {
  const {
    q = '',
    page = 1,
    perPage = 10,
    sort = 'asc',
    sortColumn = 'name'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.products.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    product =>
      (product.name.toLowerCase().includes(queryLowered))
  )
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      products: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET USER
mock.onGet('/api/products/product').reply(config => {
  const { id } = config
  const product = data.products.find(i => i.id === id)
  return [200, { product }]
})

mock.onPost('/apps/products/update').reply(config => {
  const productData = JSON.parse(config.data).data
  const product = data.products.find(product => product.id === productData.id)
  Object.assign(product, productData)
  return [200, { product }]
})

// DELETE: Deletes User
mock.onDelete('/apps/products/delete').reply(config => {
  // Get user id from URL
  let productId = config.id

  // Convert Id to number
  productId = Number(productId)

  const productIndex = data.products.findIndex(t => t.id === productId)
  data.products.splice(productIndex, 1)

  return [200]
})