// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axiosInstance from '../../../../../configs/axios'

// export const getAllData = createAsyncThunk('appVouchers/getAllData', async () => {
//   const response = await axiosInstance.get('/api/vouchers')
//   return response.data.data
// })

export const getData = createAsyncThunk('appVouchers/getData', async params => {
  const response = await axiosInstance.get('/api/vouchers', { params })
  return {
    params,
    data: response.data.data.data,
    totalPages: response.data.data.total
  }
})

export const getVoucher = createAsyncThunk('appVouchers/getVoucher', async id => {
  const response = await axiosInstance.get(`/api/vouchers/${id}/edit`)
  return response.data.data
})

export const addVoucher = createAsyncThunk('appVouchers/addVoucher', async (voucher, { dispatch, getState }) => {
  await axiosInstance.post('/api/vouchers', voucher, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  await dispatch(getData(getState().vouchers.params))
  // await dispatch(getAllData())
  return voucher
})

export const updateVoucher = createAsyncThunk('appVouchers/updateVoucher', async (voucher, { dispatch, getState }) => {
  const response = await axiosInstance.post(`/api/vouchers/${voucher.id}/update`, voucher, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  await dispatch(getData(getState().vouchers.params))
  // await dispatch(getAllData())
  // console.log(response.data)
  return response.data
})

export const deleteVoucher = createAsyncThunk('appVouchers/deleteVoucher', async (id, { dispatch, getState }) => {
  await axiosInstance.delete(`/api/vouchers/${id}/delete`)
  await dispatch(getData(getState().vouchers.params))
  // await dispatch(getAllData())
  return id
})

export const appVouchersSlice = createSlice({
  name: 'appVouchers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    // allData: [],
    selectedVoucher: null,
    loading: false
  },
  reducers: {
    resetSelectedVoucher: (state, action) => {
      state.selectedVoucher = action.payload
    }
  },
  extraReducers: builder => {
    builder
      // .addCase(getAllData.fulfilled, (state, action) => {
      //   state.allData = action.payload
      // })
      .addCase(getData.pending, (state) => {
        state.loading = true
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getVoucher.fulfilled, (state, action) => {
        state.selectedVoucher = action.payload
      })
  }
})

export const { resetSelectedVoucher } = appVouchersSlice.actions

export default appVouchersSlice.reducer
