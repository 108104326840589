// ** Initial user ability
export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth'
  }
]

export const superAdminAbility = [
  {
    action: 'manage',
    subject: 'all'
  }
]

export const adminAbility = [
  {
    action: 'read',
    subject: 'ACL'
  },
  {
    action: 'read',
    subject: 'Auth'
  },
  {
    action: 'read',
    subject: 'Dashboard'
  },
  {
    action: 'manage',
    subject: 'Category'
  },
  {
    action: 'manage',
    subject: 'Product'
  },
  {
    action: 'manage',
    subject: 'Voucher'
  },
  {
    action: 'manage',
    subject: 'Ads'
  },
  {
    action: 'read',
    subject: 'Member'
  },
  {
    action: 'manage',
    subject: 'Transaction'
  },
  {
    action: 'manage',
    subject: 'Account'
  }
]

export const _ = undefined
