import mock from "../mock"

// ** Utils
import { paginateArray } from "../utils"

const data = {
  companies: [
    {
      id: 1,
      name: "Company 1",
      address: "Bekasi",
      image: require('@src/assets/images/avatars/1.png').default
    },
    {
      id: 2,
      name: "Company 2",
      address: "Cikarang",
      image: require('@src/assets/images/avatars/2.png').default
    },
    {
      id: 3,
      name: "Company 3",
      address: "Bandung",
      image: require('@src/assets/images/avatars/3.png').default
    },
    {
      id: 4,
      name: "Company 4",
      address: "Surabaya",
      image: require('@src/assets/images/avatars/4.png').default
    },
    {
      id: 5,
      name: "Company 5",
      address: "Yogyakarta",
      image: require('@src/assets/images/avatars/5.png').default
    }
  ]
}


// GET ALL DATA
mock.onGet('/api/companies/list/all-data').reply(200, data.companies)

// POST: Add new user
mock.onPost('/apps/companies/add').reply(config => {
  // Get event from post data
  const company = JSON.parse(config.data)
  const highestValue = data.companies.reduce((a, b) => (a.id > b.id ? a : b)).id

  company.id = highestValue + 1

  data.companies.push(company)

  return [201, { company }]
})

// GET Updated DATA
mock.onGet('/api/companies/list/data').reply(config => {
  const {
    q = '',
    page = 1,
    perPage = 10,
    sort = 'asc',
    sortColumn = 'name'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.companies.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    company =>
      (company.name.toLowerCase().includes(queryLowered) ||
        company.address.toLowerCase().includes(queryLowered))
  )
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      companies: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET USER
mock.onGet('/api/companies/company').reply(config => {
  const { id } = config
  const company = data.companies.find(i => i.id === id)
  return [200, { company }]
})

mock.onPost('/apps/companies/update').reply(config => {
  const companyData = JSON.parse(config.data).data
  const company = data.companies.find(company => company.id === companyData.id)
  Object.assign(company, companyData)
  return [200, { company }]
})

// DELETE: Deletes User
mock.onDelete('/apps/companies/delete').reply(config => {
  // Get user id from URL
  let companyId = config.id

  // Convert Id to number
  companyId = Number(companyId)

  const companyIndex = data.companies.findIndex(t => t.id === companyId)
  data.companies.splice(companyIndex, 1)

  return [200]
})
