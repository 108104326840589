// ** Mock Adapter
import mock from '../mock'

const changeIndex = (arr, fromIndex, toIndex) => {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
}

const data = {
  categories: [
    {
      id: '1',
      name: 'Category 1',
      image: require('@src/assets/images/elements/apple-watch.png').default
    },
    {
      id: '2',
      name: 'Category 2',
      image: require('@src/assets/images/elements/beats-headphones.png').default
    },
    {
      id: '3',
      name: 'Category 3',
      image: require('@src/assets/images/elements/iphone-x.png').default
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/categories/list/all-data').reply(200, data.categories)

// POST: Add new user
mock.onPost('/apps/categories/add').reply(config => {
  // Get event from post data
  const category = JSON.parse(config.data)
  const highestValue = data.categories.reduce((a, b) => (a.id > b.id ? a : b)).id

  category.id = highestValue + 1

  data.categories.push(category)

  return [201, { category }]
})

// GET Updated DATA
mock.onGet('/api/categories/list/data').reply(config => {
  const {
    q = '',
    page = 1,
    perPage = 10,
    sort = 'asc',
    sortColumn = 'name'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.companies.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    category =>
      (category.name.toLowerCase().includes(queryLowered))
  )
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      categories: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET USER
mock.onGet('/api/categories/category').reply(config => {
  const { id } = config
  const category = data.categories.find(i => i.id === id)
  return [200, { category }]
})

mock.onPost('/apps/categories/update').reply(config => {
  const categoryData = JSON.parse(config.data).data
  const category = data.categories.find(category => category.id === categoryData.id)
  Object.assign(category, categoryData)
  return [200, { category }]
})

// DELETE: Deletes User
mock.onDelete('/apps/categories/delete').reply(config => {
  // Get user id from URL
  let categoryId = config.id

  // Convert Id to number
  categoryId = Number(categoryId)

  const categoryIndex = data.categories.findIndex(t => t.id === categoryId)
  data.categories.splice(categoryIndex, 1)

  return [200]
})

// ------------------------------------------------
// POST: Reorder Categories
// ------------------------------------------------
mock.onPost('/apps/categories/reorder-category').reply(config => {
  const reOrderedCategories = JSON.parse(config.data).data

  const srcIndex = data.categories.findIndex(i => Number(reOrderedCategories.categoryId) === i.id)
  const targetIndex = data.categories.findIndex(i => Number(reOrderedCategories.targetCategoryId) === i.id)

  if (srcIndex !== -1 && targetIndex !== -1) {
    changeIndex(data.categories, targetIndex, srcIndex)
  }

  return [200, data.categories]
})