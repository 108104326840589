// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** axiosInstance Imports
import axiosInstance from '../../../../configs/axios'

export const getData = createAsyncThunk('appProducts/getData', async params => {
  const response = await axiosInstance.get('/api/products', { params })
  return {
    params,
    data: response.data.data.data,
    totalPages: response.data.data.total
  }
})

export const getProduct = createAsyncThunk('appProducts/getProduct', async id => {
  const response = await axiosInstance.get(`/api/products/${id}/edit`)
  return response.data.data
})

export const addProduct = createAsyncThunk('appProducts/addProduct', async (product, { dispatch, getState }) => {
  await axiosInstance.post('/api/products', product, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  await dispatch(getData(getState().products.params))
  return product
})

export const updateProduct = createAsyncThunk('appProducts/updateProduct', async (product, { dispatch, getState }) => {
  const response = await axiosInstance.post(`/api/products/${product.id}/update`, product, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  await dispatch(getData(getState().products.params))

  return response.data
})

export const deleteProduct = createAsyncThunk('appProducts/deleteProduct', async (id, { dispatch, getState }) => {
  await axiosInstance.delete(`/api/products/${id}/delete`)
  await dispatch(getData(getState().products.params))
  return id
})

export const getSelectProduct = createAsyncThunk('appProducts/getSelectProduct', async () => {
  const response = await axiosInstance.get(`/api/get-products`)
  let options = [{ label: 'Pilih Produk', value: '' }]
  console.log(response.data)
  response.data.data.forEach(item => {
    options = [
      ...options, 
      {
        label: item.name,
        value: item.id,
        price: item.price,
        stock: item.stock
      }
    ]
  })
  console.log(options)
  return options
})

export const appProductsSlice = createSlice({
  name: 'appProducts',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selectedProduct: null,
    loading: false,
    product: {},
    productOptions: []
  },
  reducers: {
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload
    },
    setProduct: (state, action) => {
      state.product = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getData.pending, (state) => {
        state.loading = true
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getProduct.pending, (state) => {
        state.loading = true
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.loading = false
        state.selectedProduct = action.payload
      })
      .addCase(getSelectProduct.fulfilled, (state, action) => {
        state.productOptions = action.payload
      })
  }
})

export const { setSelectedProduct, setProduct } = appProductsSlice.actions

export default appProductsSlice.reducer
