import axios from 'axios'
// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const configJwt = useJwt.jwtConfig

const API_BASE_URL = 'https://api.ptmbkgroup.com'

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 5000
})

axiosInstance.interceptors.request.use(
  config => {
    // ** Get token from localStorage
    const accessToken = `Bearer ${JSON.parse(localStorage.getItem(configJwt.storageTokenKeyName))}`
    config.headers.Accept = 'application/json'
    // ** If token is present add it to request's Authorization Header
    if (accessToken) {
      // ** eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `${configJwt.tokenType} ${accessToken}`
    }
    return config
  },
  error => Promise.reject(error)
)

export default axiosInstance