// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axiosInstance from '../../../../../configs/axios'

// export const getAllData = createAsyncThunk('appVoucherRequests/getAllData', async () => {
//   const response = await axiosInstance.get('/api/member-vouchers')
//   return response.data.data
// })

export const getData = createAsyncThunk('appVoucherRequests/getData', async params => {
  const response = await axiosInstance.get('/api/member-vouchers', { params })
  return {
    params,
    data: response.data.data.data,
    totalPages: response.data.data.total
  }
})

export const getVoucherRequest = createAsyncThunk('appVoucherRequests/getVoucherRequest', async id => {
  const response = await axiosInstance.get(`/api/member-vouchers/${id}/edit`)
  return response.data.data
})

export const addVoucherRequest = createAsyncThunk('appVoucherRequests/addVoucherRequest', async (voucherRequest, { dispatch, getState }) => {
  await axiosInstance.post('/api/member-vouchers', voucherRequest, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  await dispatch(getData(getState().voucherRequests.params))
  // await dispatch(getAllData())
  return voucherRequest
})

export const updateVoucherRequest = createAsyncThunk('appVoucherRequests/updateVoucherRequest', async (voucherRequest, { dispatch, getState }) => {
  const response = await axiosInstance.post(`/api/member-vouchers/${voucherRequest.id}/update`, voucherRequest, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  await dispatch(getData(getState().voucherRequests.params))
  // await dispatch(getAllData())
  // console.log(response.data)
  return response.data
})

export const deleteVoucherRequest = createAsyncThunk('appVoucherRequests/deleteVoucherRequest', async (id, { dispatch, getState }) => {
  await axiosInstance.delete(`/api/member-vouchers/${id}/delete`)
  await dispatch(getData(getState().voucherRequests.params))
  // await dispatch(getAllData())
  return id
})

export const approveVoucherRequest = createAsyncThunk('appVoucherRequests/approveVoucherRequest', async (id, { dispatch, getState }) => {
  await axiosInstance.post(`/api/member-vouchers/${id}/approve`)
  await dispatch(getData(getState().voucherRequests.params))
  // await dispatch(getAllData())
  return id
})

export const appVoucherRequestsSlice = createSlice({
  name: 'appVoucherRequests',
  initialState: {
    data: [],
    total: 1,
    params: {},
    // allData: [],
    selectedVoucherRequest: null,
    loading: false
  },
  reducers: {
    resetSelectedVoucherRequest: (state, action) => {
      state.selectedVoucherRequest = action.payload
    }
  },
  extraReducers: builder => {
    builder
      // .addCase(getAllData.fulfilled, (state, action) => {
      //   state.allData = action.payload
      // })
      .addCase(getData.pending, (state) => {
        state.loading = true
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getVoucherRequest.fulfilled, (state, action) => {
        state.selectedVoucherRequest = action.payload
      })
  }
})

export const { resetSelectedVoucherRequest } = appVoucherRequestsSlice.actions

export default appVoucherRequestsSlice.reducer
