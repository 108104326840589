// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import kanban from '@src/views/apps/kanban/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import companies from '@src/views/apps/company/store'
import categories from '@src/views/apps/category/store'
import members from '@src/views/apps/member/store'
import admins from '@src/views/apps/admin/store'
import products from '@src/views/apps/product/store'
import vouchers from '@src/views/apps/voucher/voucher-list/store'
import voucherRequests from '@src/views/apps/voucher/request/store'
import advertisements from '@src/views/apps/advertisement/carousel/store'
import popups from '@src/views/apps/advertisement/popup/store'
import transactions from '@src/views/apps/transaction/store'
import policies from '@src/views/apps/setting/store'
import profile from '@src/views/apps/profile/store'
import dashboard from './dashboard'


const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  kanban,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  companies,
  categories,
  members,
  admins,
  products,
  vouchers,
  voucherRequests,
  advertisements,
  popups,
  transactions,
  policies,
  profile,
  dashboard
}

export default rootReducer
