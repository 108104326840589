// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axiosInstance from '../../../../configs/axios'

export const getData = createAsyncThunk('appAdmins/getData', async params => {
  const response = await axiosInstance.get('/api/users', { params })
  return {
    params,
    data: response.data.data.data,
    totalPages: response.data.data.total
  }
})

export const getAdmin = createAsyncThunk('appAdmins/getAdmin', async id => {
  const response = await axiosInstance.get(`/api/users/${id}/edit`)
  return response.data.data
})

export const addAdmin = createAsyncThunk('appAdmins/addAdmin', async (admin, { dispatch, getState }) => {
  await axiosInstance.post('/api/users', admin, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  await dispatch(getData(getState().admins.params))
  return admin
})

export const updateAdmin = createAsyncThunk('appAdmins/updateAdmin', async (admin, { dispatch, getState }) => {
  const response = await axiosInstance.post(`/api/users/${admin.id}/update`, admin, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  await dispatch(getData(getState().admins.params))
  return response.data
})

export const deleteAdmin = createAsyncThunk('appAdmins/deleteAdmin', async (id, { dispatch, getState }) => {
  await axiosInstance.delete(`/api/users/${id}/delete`)
  await dispatch(getData(getState().admins.params))
  return id
})

export const appAdminsSlice = createSlice({
  name: 'appAdmins',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selectedAdmin: null,
    loading: false
  },
  reducers: {
    resetSelectedAdmin: (state, action) => {
      state.selectedAdmin = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getData.pending, (state) => {
        state.loading = true
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getAdmin.fulfilled, (state, action) => {
        state.selectedAdmin = action.payload
      })
  }
})

export const { resetSelectedAdmin } = appAdminsSlice.actions

export default appAdminsSlice.reducer
