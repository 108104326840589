// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axiosInstance from '../../../../../configs/axios'

export const getData = createAsyncThunk('appPopups/getData', async params => {
  const response = await axiosInstance.get('/api/popups', { params })
  return {
    params,
    data: response.data.data,
    totalPages: response.data.data
  }
})

export const getAdvert = createAsyncThunk('appPopups/getAdvert', async id => {
  const response = await axiosInstance.get(`/api/popups/${id}/edit`)
  return response.data.data
})

export const addAdvert = createAsyncThunk('appPopups/addAdvert', async (advertisement, { dispatch, getState }) => {
  await axiosInstance.post('/api/popups', advertisement, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  await dispatch(getData(getState().advertisements.params))
  return advertisement
})

export const updateAdvert = createAsyncThunk('appPopups/updateAdvert', async (advertisement, { dispatch, getState }) => {
  const response = await axiosInstance.post(`/api/popups/${advertisement.id}/update`, advertisement, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  await dispatch(getData(getState().advertisements.params))
  return response.data
})

export const deleteAdvert = createAsyncThunk('appPopups/deleteAdvert', async (id, { dispatch, getState }) => {
  await axiosInstance.delete(`/api/popups/${id}/delete`)
  await dispatch(getData(getState().advertisements.params))
  return id
})

export const appPopupsSlice = createSlice({
  name: 'appPopups',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selectedAdvert: null,
    loading: false
  },
  reducers: {
    resetSelectedAdvert: (state, action) => {
      state.selectedAdvert = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getData.pending, (state) => {
        state.loading = true
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getAdvert.fulfilled, (state, action) => {
        state.selectedAdvert = action.payload
      })
  }
})

export const { resetSelectedAdvert } = appPopupsSlice.actions

export default appPopupsSlice.reducer
