// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axiosInstance from '../../../../configs/axios'

export const getData = createAsyncThunk('appMembers/getData', async params => {
  const response = await axiosInstance.get('/api/members', { params })
  return {
    params,
    data: response.data.data.data,
    totalPages: response.data.data.total
  }
})

export const getMember = createAsyncThunk('appMembers/getMember', async id => {
  const response = await axiosInstance.get(`/api/members/${id}/edit`)
  return response.data.data
})

export const addMember = createAsyncThunk('appMembers/addMember', async (member, { dispatch, getState }) => {
  await axiosInstance.post('/api/members', member, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  await dispatch(getData(getState().members.params))
  return member
})

export const updateMember = createAsyncThunk('appMembers/updateMember', async (member, { dispatch, getState }) => {
  const response = await axiosInstance.post(`/api/members/${member.id}/update`, member, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  await dispatch(getData(getState().members.params))
  return response.data
})

export const deleteMember = createAsyncThunk('appMembers/deleteMember', async (id, { dispatch, getState }) => {
  await axiosInstance.delete(`/api/members/${id}/delete`)
  await dispatch(getData(getState().members.params))
  return id
})

export const getSelectMember = createAsyncThunk('appMembers/getSelectMember', async () => {
  const response = await axiosInstance.get(`/api/select-members`)
  let options = [{ label: 'Pilih Member', value: '' }]
  Object.keys(response.data.data).forEach(key => {
    options = [
      ...options, 
      {
        label: response.data.data[key],
        value: key
      }
    ]
  })
  return options
})

export const appMembersSlice = createSlice({
  name: 'appMembers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selectedMember: null,
    loading: false,
    memberOptions: []
  },
  reducers: {
    resetSelectedMember: (state, action) => {
      state.selectedMember = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getData.pending, (state) => {
        state.loading = true
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getMember.fulfilled, (state, action) => {
        state.selectedMember = action.payload
      })
      .addCase(getSelectMember.fulfilled, (state, action) => {
        state.memberOptions = action.payload
      })
  }
})

export const { resetSelectedMember } = appMembersSlice.actions

export default appMembersSlice.reducer
